<template>
  <!-- FOOTER SECTION -->
  <v-container fluid>
    <footer class="footer py-7 py-lg-16">
      <div class="text-center text-dark-emphasis">
        <p class="footer-title my-0">Lifeline Educational Solutions</p>
        <small class="footer-copyright text-dark-50 d-block py-0"
          >&copy; {{ new Date().getFullYear() }} - Copyright: All rights
          reserved</small
        >
        <div class="footer-icons pb-sm-5">
          <a href="https://web.facebook.com/Lifelineeducationalsolutions" target="_blank">
            <span class="mdi mdi-facebook text-dark"></span
          ></a>
          <span class="mdi mdi-twitter mx-5 text-dark"></span>
          
          <span class="mdi mdi-whatsapp text-dark"></span>
        </div>
      </div>
    </footer>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
#footer {
  padding: 10%;
}
.footer-icons {
  color: red;
  /* background-color: #bb2525; */
  font-size: 2rem;
  margin: 0 7px;
  padding-bottom: 2%;
}

.footer-title {
  font-size: 3vw;
}

.footer-copyright {
  font-size: 1.5vw;
}

.laptop {
  position: relative;
}

/* Contact Email and Phone */
.phone {
  background-color: rgba(0, 0, 0, 0.9);
  border: 2px solid white;
  border-radius: 10px;
  width: 60%;
  padding: 5% 3%;
  position: absolute;
  bottom: 20%;
  left: 20%;
}

.email {
  background-color: green;
  border-radius: 10px;
  width: 60%;
  padding: 5% 3%;
  position: absolute;
  bottom: 20%;
  left: 20%;
}

.mail,
.tel {
  font-size: 1.7vw;
  font-family: Georgia, "Times New Roman", Times, serif;
}
</style>
